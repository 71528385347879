
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import { Page } from '@/interfaces/api/Page';
import { namespace } from 'vuex-class';
import Filter from '@/interfaces/api/Filter';
import VWrapper from '@/components/shared/VWrapper.vue';
import SyncTable from '@/components/sync/SyncTable.vue';
import { SyncJob } from '@/interfaces/models/SyncJob';
import { Venue } from '@/interfaces/models/Venue';
import { SyncConfig } from '@/interfaces/models/SyncConfig';

const sync = namespace('sync');
const app = namespace('app');

@Component({
  components: { VWrapper, SyncTable },
})
export default class Statistics extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  @sync.State('items') public items!: SyncJob[];
  @sync.State('filter') public filter!: Filter;
  @sync.State((state) => state.pagination.total) public total!: number;
  @sync.Action('fetch') public getSyncJobs: any;
  @sync.Action('setFilter') public setFilter: any;
  @sync.Action('stopSync') public stopSync: any;
  @sync.Action('getItemEntries') public getItemEntries: any;

  public configDialog: Boolean = false;
  public config: SyncConfig | null = null;

  get entriesKeyProps() {
    return ['articles', 'articleCategories', 'optionGroups', 'venue', 'optionArticles'];
  }

  public mounted() {
    this.setFilter({});
    this.getSyncJobs({ page: 1, limit: 10 });
  }

  public async fetchSyncJobs(page: number = 1) {
    this.$startLoading('stats');
    this.setFilter({ ...this.filter });
    await this.getSyncJobs({ page });
    this.$stopLoading('stats');
  }

  public onConfig(config: SyncConfig) {
    this.config = config;
    this.configDialog = true;
  }

  public async onStopSync(id: string) {
    this.$startLoading('stopSync');
    await this.stopSync(id);
    this.$stopLoading('stopSync');
  }

  public async onItemView(id: string) {
    this.$startLoading('viewSyncItem');
    await this.getItemEntries(id);
    this.$stopLoading('viewSyncItem');
  }

  public onPageChange(page: Page) {
    if (page) {
      this.fetchSyncJobs(page.page);
    }
  }

  public onSearch(queries: any) {
    this.setFilter({ ...this.filter, ...queries });
    this.fetchSyncJobs();
  }

  public joinStringArray(arr: any, separator: string = ', ') {
    if (!arr) return '';
    return arr?.join(separator);
  }

  public transformConfigFields(key: string, separator: string = ', ') {
    if (!this.config?.update) return '';
    const update: { [key: string]: any } = this.config.update;
    return update[key].join(separator);
  }
}
